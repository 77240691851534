import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/atlassian/pipelines/agent/build/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { PackageMetadata } from '@happeo/docs-tools';
import pkg from "../package.json";
import { Banner } from '../src/index';
import { BodyUI } from "@happeouikit/typography";
import { ButtonPrimary, ButtonSecondary, IconButton } from "@happeouikit/buttons";
import { active } from "@happeouikit/colors";
import { IconBulb, IconClose } from "@happeouikit/icons";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "banner"
    }}>{`Banner`}</h1>
    <PackageMetadata pkg={pkg} mdxType="PackageMetadata" />
    <h2 {...{
      "id": "info-banner-with-title"
    }}>{`Info Banner with title`}</h2>
    <Playground __position={1} __code={'<div style={{ width: \'400px\' }}>\n  <Banner title={\'USPS has updated their rates\'} />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "400px"
      }}>
        <Banner title={"USPS has updated their rates"} mdxType="Banner" />
    </div>        
    </Playground>
    <h2 {...{
      "id": "info-banner-with-title-and-close-button-right-action"
    }}>{`Info Banner with title and close button (right action)`}</h2>
    <Playground __position={2} __code={'<div style={{ width: \'400px\' }}>\n  <Banner\n    title={\'USPS has updated their rates\'}\n    rightAction={\n      <IconButton\n        icon={IconClose}\n        isActionIcon\n        onClick={() => console.log(\'Close clicked\')}\n      />\n    }\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "400px"
      }}>
        <Banner title={"USPS has updated their rates"} rightAction={<IconButton icon={IconClose} isActionIcon onClick={() => console.log("Close clicked")} mdxType="IconButton" />} mdxType="Banner" />
    </div>        
    </Playground>
    <h2 {...{
      "id": "info-banner-with-title-and-short-description"
    }}>{`Info Banner with title and short description`}</h2>
    <Playground __position={3} __code={'<div style={{ width: \'400px\' }}>\n  <Banner\n    state={\'info\'}\n    title={\'USPS has updated their rates\'}\n    description={\'Before fulfilling this order\'}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "400px"
      }}>
        <Banner state={"info"} title={"USPS has updated their rates"} description={"Before fulfilling this order"} mdxType="Banner" />
    </div>        
    </Playground>
    <h2 {...{
      "id": "info-banner-with-title-short-description-and-close-button-right-action"
    }}>{`Info Banner with title, short description and close button (right action)`}</h2>
    <Playground __position={4} __code={'<div style={{ width: \'400px\' }}>\n  <Banner\n    state={\'info\'}\n    title={\'USPS has updated their rates\'}\n    description={\'Before fulfilling this order\'}\n    rightAction={\n      <IconButton\n        icon={IconClose}\n        onClick={() => console.log(\'Close clicked\')}\n      />\n    }\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "400px"
      }}>
        <Banner state={"info"} title={"USPS has updated their rates"} description={"Before fulfilling this order"} rightAction={<IconButton icon={IconClose} onClick={() => console.log("Close clicked")} mdxType="IconButton" />} mdxType="Banner" />
    </div>        
    </Playground>
    <h2 {...{
      "id": "error-banner-with-title-and-long-description"
    }}>{`Error Banner with title and long description`}</h2>
    <Playground __position={5} __code={'<div style={{ width: \'400px\' }}>\n  <Banner\n    state={\'error\'}\n    title={\'USPS has updated their rates\'}\n    description={\n      \'Before fulfilling this order or capturing payment, please review the Risk Analysis and determine if this order is fraudulent.\'\n    }\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "400px"
      }}>
        <Banner state={"error"} title={"USPS has updated their rates"} description={"Before fulfilling this order or capturing payment, please review the Risk Analysis and determine if this order is fraudulent."} mdxType="Banner" />
    </div>
    </Playground>
    <h2 {...{
      "id": "warning-banner-with-title-and-long-description"
    }}>{`Warning Banner with title and long description`}</h2>
    <Playground __position={6} __code={'<div style={{ width: \'400px\' }}>\n  <Banner\n    state={\'warn\'}\n    title={\'USPS has updated their rates\'}\n    description={\n      \'Before fulfilling this order or capturing payment, please review the Risk Analysis and determine if this order is fraudulent.\'\n    }\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "400px"
      }}>
        <Banner state={"warn"} title={"USPS has updated their rates"} description={"Before fulfilling this order or capturing payment, please review the Risk Analysis and determine if this order is fraudulent."} mdxType="Banner" />
    </div>
    </Playground>
    <h2 {...{
      "id": "banner-with-custom-icon"
    }}>{`Banner with custom icon`}</h2>
    <Playground __position={7} __code={'<div style={{ width: \'568px\' }}>\n  <Banner\n    state={\'warn\'}\n    title={\'Broken Link\'}\n    description={\'Make sure you know about these changes\'}\n    icon={<IconBulb />}\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "568px"
      }}>
        <Banner state={"warn"} title={"Broken Link"} description={"Make sure you know about these changes"} icon={<IconBulb mdxType="IconBulb" />} mdxType="Banner" />
    </div>
    </Playground>
    <h2 {...{
      "id": "success-banner-with-title-description-and-action"
    }}>{`Success Banner with title, description and action`}</h2>
    <Playground __position={8} __code={'<div style={{ width: \'400px\' }}>\n  <Banner\n    state={\'success\'}\n    title={\'USPS has updated their rates\'}\n    description={\n      \'Before fulfilling this order or capturing payment, please review the Risk Analysis and determine if this order is fraudulent.\'\n    }\n    action={\n      <ButtonPrimary\n        onClick={() => console.log(\'execute some action\')}\n        text=\"Action\"\n      />\n    }\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "400px"
      }}>
        <Banner state={"success"} title={"USPS has updated their rates"} description={"Before fulfilling this order or capturing payment, please review the Risk Analysis and determine if this order is fraudulent."} action={<ButtonPrimary onClick={() => console.log("execute some action")} text='Action' mdxType="ButtonPrimary" />} mdxType="Banner" />
    </div>
    </Playground>
    <h2 {...{
      "id": "success-banner-with-title-description-action-and-right-action"
    }}>{`Success Banner with title, description, action and right action`}</h2>
    <Playground __position={9} __code={'<div style={{ width: \'400px\' }}>\n  <Banner\n    state={\'success\'}\n    title={\'USPS has updated their rates\'}\n    description={\n      \'Before fulfilling this order or capturing payment, please review the Risk Analysis and determine if this order is fraudulent.\'\n    }\n    action={\n      <ButtonPrimary\n        onClick={() => console.log(\'execute some action\')}\n        text=\"Action\"\n      />\n    }\n    rightAction={\n      <IconButton\n        icon={IconClose}\n        onClick={() => console.log(\'Close clicked\')}\n      />\n    }\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "400px"
      }}>
        <Banner state={"success"} title={"USPS has updated their rates"} description={"Before fulfilling this order or capturing payment, please review the Risk Analysis and determine if this order is fraudulent."} action={<ButtonPrimary onClick={() => console.log("execute some action")} text='Action' mdxType="ButtonPrimary" />} rightAction={<IconButton icon={IconClose} onClick={() => console.log("Close clicked")} mdxType="IconButton" />} mdxType="Banner" />
    </div>
    </Playground>
    <h2 {...{
      "id": "warning-banner-with-title-description-and-multiple-action-items"
    }}>{`Warning banner with title, description and multiple action items`}</h2>
    <Playground __position={10} __code={'<div style={{ width: \'400px\' }}>\n  <Banner\n    state={\'warn\'}\n    title={\'Example with multiple actions\'}\n    description={\n      \'If you need multiple action items just wrap all of them with a fragment or maybe a div to help with the layout.\'\n    }\n    action={\n      <>\n        <ButtonPrimary\n          onClick={() => console.log(\'execute some action\')}\n          text=\"Action\"\n        />\n        <ButtonSecondary\n          onClick={() => console.log(\'execute some action\')}\n          text=\"Cancel\"\n        ></ButtonSecondary>\n      </>\n    }\n  />\n</div>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      PackageMetadata,
      pkg,
      Banner,
      BodyUI,
      ButtonPrimary,
      ButtonSecondary,
      IconButton,
      active,
      IconBulb,
      IconClose,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
    <div style={{
        width: "400px"
      }}>
        <Banner state={"warn"} title={"Example with multiple actions"} description={"If you need multiple action items just wrap all of them with a fragment or maybe a div to help with the layout."} action={<>
                    <ButtonPrimary onClick={() => console.log("execute some action")} text='Action' mdxType="ButtonPrimary" />
                    <ButtonSecondary onClick={() => console.log("execute some action")} text='Cancel' mdxType="ButtonSecondary"></ButtonSecondary>
                </>} mdxType="Banner" />
    </div>
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={Banner} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      